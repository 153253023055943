<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->
        
        <h1 class="titles">
          <span style="color: #8b8c8c">Monumentos y Patrimonio</span>
        </h1>



        <br><br>

    <br><br>




<h4 class="titles">
          <span style="color: #8b8c8c">Letras monumentales</span>
        </h4>
        <br>


                    
                    <b-row align-h="center">
              <b-col cols="11" md="8" lg="5" xl="5"  class="mb-5" style="height:380px;">
                  <div style="height:100%; width: 100%;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:380px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/r5naQQOb6Yk"
                    ></b-embed>
                  </div>
                </b-col>
                
              </b-row>

    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
            Apostadas en el centro de la cabecera municipal.Es un elemento dentro del municipio que es un atractivo turístico y visual hacia el visitante.

          </b>
        </p>
      </b-col>
    </b-row>
 <br><br><br>


<h4 class="titles">
          <span style="color: #8b8c8c">Paseo de las naciones</span>
        </h4>
        <br>


                    <b-row align-h="center">
              <b-col cols="11" md="8" lg="5" xl="5"  class="mb-5" style="height:380px;">
                  <div style="height:100%; width: 100%;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:380px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/TaCI033dl_U"
                    ></b-embed>
                  </div>
                </b-col>
                
              </b-row>

    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
            Antigua carretera a Pirámides, comunica la cabecera municipal con el circuito arqueológico de Teotihuacán.Es una avenida principal de la cabecera municipal en la cual se encuentran 41 banderas internacionales.


          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>



<h4 class="titles">
          <span style="color: #8b8c8c">Museo de Quetzalpapalotl</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/museo.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
            Inaugurado el 13 de octubre de 1998, su objetivo es crear una plataforma permanente de exposiciones de diversas piezas y materiales que hagan referencia desde el pasado más antiguo de la comunidad hasta la época contemporánea. El acervo de este museo se ha integrado gracias a la confianza de la comunidad, que ha donado o prestado piezas y objetos para su exposición.  


          </b>
        </p>
        <p>
          <b>
            Av. Cuauhtémoc, 20, Teotihuacán Centro. 594-933-2017
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>



      <h4 class="titles">
          <span style="color: #8b8c8c">Catedral de cabecera municipal</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/catedral.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
            La catedral fue edificada en 1548, ahora convertida en el templo de Diócesis de Teotihuacán, fue edificada para la veneración de san Juan Bautista, en su interior se pueden observar una serie de elementos arquitectónicos que resaltan, alberga una colección de óleos de temas religiosos, como lo es el mural que representa el sueño de Jacob y las escaleras al cielo.
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>



<h4 class="titles">
          <span style="color: #8b8c8c">Estación de Ferrocarril en San Sebastián.</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/estacion.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
Construyo un punto importante para la vida política, económica y social de la región, actualmente se conserva esta estación que se encuentra aproximadamente a 2 km. De la cabecera municipal.
          </b>
        </p>
        <p>
          <b>
           Tal estación ostenta el nombre Teotihuacán, y está administrada por Ferrovalle, según consta por el cartelillo de tal empresa fijado en el edificio.
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>


<h4 class="titles">
          <span style="color: #8b8c8c">Fuente y glorieta con luz.</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/fuenteluz.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
Carretera Pirámides s/n. Frente a campo militar, Teotihuacán, centro.
          </b>
        </p>
        <p>
          <b>
           Proporciona gran vista nocturna a los paseantes que circulan en la avenida pirámides cerca de la zona arqueológica de Teotihuacán.
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>



<h4 class="titles">
          <span style="color: #8b8c8c">Fuente del Danzante</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/danzante.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
Juárez 1, Teotihuacán Centro.
          </b>
        </p>
        <p>
          <b>
           Fue inaugurada el 17 de Abril de 2016, cuando se logro obtener el record Guiness a la danza ceremonial más grande del mundo, y para enaltecer la gran labor de los danzantes que mantienen vigente la cultura prehispánica en Teotihuacán.
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>





<h4 class="titles">
          <span style="color: #8b8c8c">Fuente de Santa María Coátlan</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/fuentesantamaria.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
Centro Santa María Coátlan
          </b>
        </p>
        <p>
          <b>
           Fuente histórica que inicialmente estaba en el centro de san juan Teotihuacan
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>




<h4 class="titles">
          <span style="color: #8b8c8c">Iglesia de San Francisco de Asís</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/iglesiasanfrancisco.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
Edificio Religioso. San Francisco Centro, Teotihuacán.

          </b>
        </p>
        <p>
          <b>
           La parroquia de San Francisco de Asís es una construcción de la orden franciscana y refleja la arquitectura novohispana de la época. Resguarda diversas pinturas al óleo representativas del catolicismo. La más importante de ellas es un mural de la estigmatización de san Francisco de Asís. 
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>





<h4 class="titles">
          <span style="color: #8b8c8c">Capilla Asunción de María.</span>
        </h4>
        <br>


                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/7%20monumentos/iglesiasantamaria.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br>
    <b-row align-h="center">
      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
        <p>
          <b>
Edificio Religioso Santa María Coatlan Centro, Teotihuacán.


          </b>
        </p>
        <p>
          <b>
           Este templo, edificado en el siglo XVII, es uno de los más antiguos de la orden franciscana en la región y está dedicado a la veneración de la bienaventurada virgen María.
          </b>
        </p>
      </b-col>
    </b-row>
 <br><br>






    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
     
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
 

        ]
      }
    }
    
  }
</script>